import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {Product} from "../../../types/ProductTypes";
import Typography from "@mui/material/Typography";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '85%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 700,
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface ProductsListProps {
    setId: (val: number) => void;
    currentId: number;
    products: Product[];
}

export default function ProductsList({setId, products, currentId}: ProductsListProps) {
    const [open, setOpen] = React.useState(false);
    const [list, setList] = React.useState<Product[]>([]);

    function handleOpen() {
        setOpen(true)
        setList(products)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen} sx={{width: '100%'}}>
                <Box
                    component="img"
                    sx={{
                        height: "auto",
                        width: "100%",
                        maxHeight: "150px",
                        maxWidth: "150px",
                        minHeight: "100px",
                        minWidth: "100%",
                    }}
                    alt={products[currentId - 1].name}
                    src={products[currentId - 1].iconUrl}
                />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{minWidth: "100px", padding: '10px'}}>
                                            <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                                Product ID
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{minWidth: "100px", padding: '10px'}}>
                                            <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                                Product name
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{minWidth: "300px", padding: '10px'}}>
                                            <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                                Icon
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list?.map((values, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => {setId(values.id); handleClose()}}
                                            sx={{
                                                '&:last-child td, &:last-child th': {border: 0},
                                                textDecoration: "none",
                                                cursor: 'pointer',
                                                "&:hover": {
                                                    backgroundColor: '#eff2f7'
                                                },
                                        }}
                                        >
                                            <TableCell align="center">{values.id}</TableCell>
                                            <TableCell align="center">{values.name}</TableCell>
                                            <TableCell key={index} align="center">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: "auto",
                                                        width: "100%",
                                                        maxHeight: "150px",
                                                        maxWidth: "200px",
                                                    }}
                                                    alt={values.name}
                                                    src={values.iconUrl}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}