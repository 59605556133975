import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {ResponseList} from "../types/Types";
import {
    GenerateIcon,
    GenerateProduct,
    IconsProduct,
    Product,
    SortAndPagination,
} from "../types/ProductTypes";

//get query

export async function getAllProducts(filters:SortAndPagination): Promise<ResponseList<Product>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/products?${params}`, {method:"GET"});
    return await response.json();
}

export async function getAllIcons(filters:SortAndPagination): Promise<ResponseList<IconsProduct>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/icons?${params}`, {method:"GET"});
    return await response.json();
}

// edit query

export async function editProduct(id: number, filters:Product): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/products/${id}`, {method:"PUT", body: params});
}

export async function editIcon(id: number, filters:IconsProduct): Promise<void>  {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    await fetch(`/api/icons/${id}?${params}`, {method:"PUT"});
}

// generate query

export async function generateProduct(filters: GenerateProduct): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/products`, {method: "POST", body: params});
}

export async function generateIcon(filters: GenerateIcon): Promise<void>  {
    let params = new URLSearchParams(mapAnyToMapString(filters))
    await fetch(`/api/icons?${params}`, {method: "POST"});
}

//delete query

export async function deleteProduct(id:number): Promise<void>  {
    await fetch(`/api/products/${id}`, {method: "DELETE"});
}

export async function deleteIcon(id:number): Promise<void>  {
    await fetch(`/api/icons/${id}`, {method: "DELETE"});
}