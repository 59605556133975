import React, {useEffect} from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Button, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {addSpaces, getHeaders} from "../../../utils/utils";
import {BavariaPlantDict} from "../../../types/DictionaryTypes";
import {
    deleteBavariaPlantDict,
    getBavariaPlantDict,
} from "../../../services/DictionaryService";
import DeleteIcon from '@mui/icons-material/Delete';
import GenerateBavariaPlantDict from "./modals/GenerateBavariaPlantDict";
import EditBavariaPlantDict from "./modals/EditBavariaPlantDict";
import {Product} from "../../../types/ProductTypes";

interface BavariaPlantTableProps {
    reloadTables: boolean;
    products: Product[];
}

export const BavariaPlantTable = ({reloadTables, products}: BavariaPlantTableProps) => {
    const [data, setData] = React.useState<BavariaPlantDict[]>([])
    const [total, setTotal] = React.useState(0)
    const [headers, setHeaders] = React.useState([""])
    const [reload, setReload] = React.useState<boolean>(true)

    useEffect(() => {
        (async () => {
            const result = await getBavariaPlantDict()
            setData(result.list);
            setHeaders(getHeaders(result.list))
            setTotal(result.total.totalCount)
        })()
    }, [reloadTables, reload])

    function reloadThisTable() {
        reload ? setReload(false) : setReload(true);
    }

    return(
        <>
            <Box mt={"4em"} mb={"3em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>Bavaria Plant Dictionary</Typography>
                <Typography variant={"h5"} component={"span"}>
                    {total}
                </Typography>
            </Box>
            <TableContainer component={Paper} sx={{mb: '3em'}}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers.map((field) => (
                                <TableCell key={field} align="center" sx={{minWidth: "80px", padding: 0}}>
                                    <Button sx={{fontSize: "12px", height: "100%", width: "80%", padding: "15px", margin: 0}} color={"inherit"}>{addSpaces(field)}</Button>
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    EDIT DATA
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    DELETE DATA
                                </Typography>
                            </TableCell>
                            {data.length ?
                                <TableCell align="center">
                                    <GenerateBavariaPlantDict products={products} reload={reloadThisTable}/>
                                </TableCell>
                                : <></>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((values, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                {Object.values(values).map((value: any, index) => (
                                    <TableCell key={index} align="center">{value}</TableCell>
                                ))}
                                <TableCell align="center">
                                    <EditBavariaPlantDict products={products} reload={reloadThisTable} values={values} />
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => {deleteBavariaPlantDict(values.id).then(reloadThisTable)}}>
                                        <DeleteIcon sx={{color: "#283593"}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}