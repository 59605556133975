import {
    SortAndPagination
} from "../types/ProductTypes";
import {RecipesParams, ResponseList} from "../types/Types";
import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {
    GenerateBavRecipe,
    GenerateBavRecipeGain, GenerateBavRecipeSpend, GenerateSchwRecipe, GenerateSchwRecipeGain, GenerateSchwRecipeSpend,
    RecipeBav,
    RecipeBavGain,
    RecipeBavSpend, Recipes, RecipeSchw, RecipeSchwGain, RecipeSchwSpend, RecipesGain, RecipesSpend
} from "../types/RecipesTypes";



// Bavaria

// GET query

export async function getAllBavRecipes(filters:SortAndPagination): Promise<ResponseList<RecipeBav>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/bavaria/recipe?${params}`, {method:"GET"});
    return await response.json();
}

export async function getAllBavRecipesGain(filters:SortAndPagination): Promise<ResponseList<RecipeBavGain>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/bavaria/recipe-gain?${params}`, {method:"GET"});
    return await response.json();
}

export async function getAllBavRecipesSpend(filters:SortAndPagination): Promise<ResponseList<RecipeBavSpend>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/bavaria/recipe-spend?${params}`, {method:"GET"});
    return await response.json();
}

// PUT query

export async function editBavRecipe(id: number, filters:RecipeBav): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/recipe/${id}`, {method: "PUT", body: params});
}

export async function editBavRecipeGain(id: number, filters:RecipeBavGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/recipe-gain/${id}`, {method: "PUT", body: params});
}

export async function editBavRecipeSpend(id: number, filters:RecipeBavSpend): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/recipe-spend/${id}`, {method: "PUT", body: params});
}

// POST query

export async function generateBavRecipe(filters: GenerateBavRecipe): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/recipe`, {method: "POST", body: params});
}

export async function generateBavRecipeGain(filters: GenerateBavRecipeGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/recipe-gain`, {method: "POST", body: params});
}

export async function generateBavRecipeSpend(filters: GenerateBavRecipeSpend): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/recipe-spend`, {method: "POST", body: params});
}

// DELETE query

export async function deleteBavRecipe(id:number): Promise<void>  {
    await fetch(`/api/bavaria/recipe/${id}`, {method: "DELETE"});
}

export async function deleteBavRecipeGain(id:number): Promise<void>  {
    await fetch(`/api/bavaria/recipe-gain/${id}`, {method: "DELETE"});
}

export async function deleteBavRecipeSpend(id:number): Promise<void>  {
    await fetch(`/api/bavaria/recipe-spend/${id}`, {method: "DELETE"});
}

// Schwarzerwald

// GET query

export async function getAllSchwRecipes(filters:SortAndPagination): Promise<ResponseList<RecipeSchw>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/schwarzerwald/recipe?${params}`, {method:"GET"});
    return await response.json();
}

export async function getAllSchwRecipesGain(filters:SortAndPagination): Promise<ResponseList<RecipeSchwGain>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/schwarzerwald/recipe-gain?${params}`, {method:"GET"});
    return await response.json();
}

export async function getAllSchwRecipesSpend(filters:SortAndPagination): Promise<ResponseList<RecipeSchwSpend>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/schwarzerwald/recipe-spend?${params}`, {method:"GET"});
    return await response.json();
}

// PUT query

export async function editSchwRecipe(id: number, filters:RecipeSchw): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/recipe/${id}`, {method: "PUT", body: params});
}

export async function editSchwRecipeGain(id: number, filters:RecipeSchwGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/recipe-gain/${id}`, {method: "PUT", body: params});
}

export async function editSchwRecipeSpend(id: number, filters:RecipeSchwSpend): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/recipe-spend/${id}`, {method: "PUT", body: params});
}

// POST query

export async function generateSchwRecipe(filters: GenerateSchwRecipe): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/recipe`, {method: "POST", body: params});
}

export async function generateSchwRecipeGain(filters: GenerateSchwRecipeGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/recipe-gain`, {method: "POST", body: params});
}

export async function generateSchwRecipeSpend(filters: GenerateSchwRecipeSpend): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/recipe-spend`, {method: "POST", body: params});
}

// DELETE query

export async function deleteSchwRecipe(id:number): Promise<void>  {
    await fetch(`/api/schwarzerwald/recipe/${id}`, {method: "DELETE"});
}

export async function deleteSchwRecipeGain(id:number): Promise<void>  {
    await fetch(`/api/schwarzerwald/recipe-gain/${id}`, {method: "DELETE"});
}

export async function deleteSchwRecipeSpend(id:number): Promise<void>  {
    await fetch(`/api/schwarzerwald/recipe-spend/${id}`, {method: "DELETE"});
}

//common tables

export async function getAllRecipes(filters:RecipesParams): Promise<ResponseList<Recipes>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/recipe?${params}`, {method:"GET"});
    return await response.json();
}

export async function editRecipe(id: number, filters:Recipes): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/recipe/${id}`, {method: "PUT", body: params});
}

export async function postRecipe(filters: Recipes): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/recipe`, {method: "POST", body: params});
}

export async function deleteRecipe(id:number): Promise<void>  {
    await fetch(`/api/recipe/${id}`, {method: "DELETE"});
}

//gain

export async function getAllRecipesGain(filters:RecipesParams): Promise<ResponseList<RecipesGain>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/recipe-gain?${params}`, {method:"GET"});
    return await response.json();
}

export async function editRecipeGain(id: number, filters:RecipesGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/recipe-gain/${id}`, {method: "PUT", body: params});
}

export async function postRecipeGain(filters: RecipesGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/recipe-gain`, {method: "POST", body: params});
}

export async function deleteRecipeGain(id:number): Promise<void>  {
    await fetch(`/api/recipe-gain/${id}`, {method: "DELETE"});
}

//spend

export async function getAllRecipesSpend(filters:RecipesParams): Promise<ResponseList<RecipesSpend>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/recipe-spend?${params}`, {method:"GET"});
    return await response.json();
}

export async function editRecipeSpend(id: number, filters:RecipesGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/recipe-spend/${id}`, {method: "PUT", body: params});
}

export async function postRecipeSpend(filters: RecipesGain): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/recipe-spend`, {method: "POST", body: params});
}

export async function deleteRecipeSpend(id:number): Promise<void>  {
    await fetch(`/api/recipe-spend/${id}`, {method: "DELETE"});
}