import React, {useEffect} from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import {SortArrow} from "../../common/SortArrow";
import {addSpaces, replaceCamelCase} from "../../../utils/utils";
import {
    ListTransIn,
    ListTransOut, ResponseTransactions,
    TotalTransIn,
    TotalTransOut,
    TransactionsFilter
} from "../../../types/TransactionsTypes";

interface TransactionsTableProps {
    reloadTables: boolean;
    userId: number;
    getData: (filters: TransactionsFilter, id:number) =>
        Promise<ResponseTransactions<ListTransIn, TotalTransIn>>
        |
        Promise<ResponseTransactions<ListTransOut, TotalTransOut>>
    propsHeaders?: string[];
    pagination: boolean;
    dateTo: Date | null;
    dateFrom: Date | null;
}

export const TransactionsTable = ({getData, propsHeaders, pagination, dateTo, dateFrom, userId, reloadTables}: TransactionsTableProps) => {
    const [data, setData] = React.useState<ListTransIn[] | ListTransOut[]>()
    const [totalData, setTotalData] = React.useState<TotalTransIn | TotalTransOut>()
    const [page, setPage] = React.useState(0)
    const [sortField, setSortField] = React.useState<string>("")
    const [limit, setLimit] = React.useState(5)
    const [offset, setOffset] = React.useState(0)
    const [sortDirection, setSortDirection] = React.useState<"DESC" | "">("")
    const [selectedField, setSelectedField] = React.useState<string>("")
    const [total, setTotal] = React.useState<number>(0)
    const [headers, setHeaders] = React.useState<Array<string>>([])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            const result = await getData({dateTo, dateFrom, limit, offset, sortField, sortDirection}, userId)
            if (pagination) {
                setData(result.list);
            } else {
                setTotalData(result.total)
            }
            setTotal(result.total.totalCount)
            if (!propsHeaders) {
                setHeaders(Object.keys(result.total))
            } else {
                setHeaders(propsHeaders)
            }
        })()
    }, [limit, offset, sortField, sortDirection, dateFrom, dateTo, reloadTables])
    /* eslint-enable */

    function sortByField(field:string) {
        setSortField(replaceCamelCase(field))
        setSelectedField(field)
    }

    function directionDESC() {
        setSortDirection("DESC")
    }

    function directionDrop() {
        setSelectedField("")
        setSortField("")
        setSortDirection("")
    }

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
        setOffset(newPage * limit)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setLimit(parseInt(event.target.value, 10));
        setOffset(0)
    }


    return (
        <TableContainer component={Paper} >
            <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {headers?.map((field) => (
                            <TableCell key={field} align="center" sx={{minWidth: "175px", padding: 0}}>
                                {field.includes("total")
                                    ?
                                    <Typography sx={{fontSize: "12px", fontWeight: "500", padding: "15px"}}>
                                        {addSpaces(field).toUpperCase()}
                                    </Typography>
                                    :
                                    <>
                                        <Button sx={{fontSize: "12px", height: "100%", width: "60%", padding: "15px", margin: 0}} color={"inherit"}
                                                onClick={() => sortByField(field)}>{addSpaces(field)}</Button>
                                        {selectedField === field
                                            ? <SortArrow field={field} directionDESC={directionDESC} directionDrop={directionDrop}/>
                                            : ""
                                        }
                                    </>
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pagination ?
                         Object.entries(!totalData).map((values, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                {Object.values(values).map((value: any, index) => (
                                    <TableCell key={index} align="center">{value}</TableCell>
                                ))}
                            </TableRow>
                        )) :
                        data?.map((values, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                {Object.values(values).map((value: any, index) => (
                                    <TableCell key={index} align="center">{value}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            {pagination ?
                <>
                    <hr/>
                    <TablePagination
                        component="div"
                        count={total}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25, 100]}
                    />
                </>
                : <></>}
        </TableContainer>
    )
};

