import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {Review} from "../../types/ReviewsTypes";
import {editReview} from "../../services/ReviewsService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditReviewProps {
    values: Review;
    reload: () => void;
}

export default function EditReview({values, reload}: EditReviewProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [review, setReview] = React.useState<string>('')
    const [time, setTime] = React.useState<string>('')
    const [userId, setUserId] = React.useState<number>(0)

    function handleOpen() {
        setId(values.id)
        setReview(values.review)
        setTime(values.time)
        setUserId(values.userId)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            type={"number"}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            helperText={'review id'}
                            label={'id'}
                            sx={{width: '96%'}}
                        />
                        <TextField
                            value={time}
                            onChange={(e) =>
                                setTime(e.target.value)
                            }
                            helperText={'edit time'}
                            label={'time'}
                            sx={{width: '96%'}}
                        />
                        <TextField
                            value={userId}
                            type={"number"}
                            onChange={(e) =>
                                setUserId(Number(e.target.value))
                            }
                            helperText={'edit userId'}
                            label={'userId'}
                            sx={{width: '96%'}}
                        />
                        <TextField
                            value={review}
                            multiline
                            rows={5}
                            type={"text"}
                            onChange={(e) =>
                                setReview(e.target.value)
                            }
                            helperText={'edit review'}
                            label={'review'}
                            sx={{width: '96%'}}
                        />
                        <Button onClick={() => {
                            editReview(id, {id, review, time, userId}).then(() => {handleClose(); reload()});
                        }} size={'large'} sx={{width: '48%'}} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}