import React from 'react'
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import {SchwarzerwaldPlantTable} from "../../components/schwarzerwald/dictionary/SchwarzerwaldPlantTable";

export const DictionarySchwPage = () => {
    const [loading, setLoading] = React.useState(false);
    const [reloadTables, setReloadTables] = React.useState<boolean>(false)

    const reload = () => {
        setLoading(true);
        setTimeout(() => {
            if(reloadTables) {
                setReloadTables(false)
            } else {
                setReloadTables(true)
            }
            setLoading(false)
        }, 2000);
    }

    return(
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                mb: "2em",
                mt: "3em"
            }}>
                <LoadingButton
                    onClick={reload}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{pr: "30px", pl: "30px"}}
                >
                    Reload
                </LoadingButton>
            </Box>
            <Box mt={"3em"} mb={"3em"}>
                <SchwarzerwaldPlantTable reloadTables={reloadTables}/>
            </Box>
        </>
    )
}