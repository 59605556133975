import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {MenuItem, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {changeMoneyForUser} from "../../../services/TransactionsService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

const options = [
    {
        value: 'direct',
        label: 'direct',
    },
    {
        value: 'message',
        label: 'message',
    },
]
interface TransactionModalProps {
    userId: number;
    reload: () => void;
}

export default function TransactionModal({userId, reload}: TransactionModalProps) {
    const [open, setOpen] = React.useState(false);
    const [changeMoney, setChangeMoney] = React.useState<number>(0)
    const [changeCristal, setChangeCristal] = React.useState<number>(0)
    const [editingOption, setEditingOption] = React.useState("direct")
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function ChangeUserBalance() {
        changeMoneyForUser(userId, {userId, editingOption, changeMoney, changeCristal})
        handleClose()
        reload()
    }

    const changeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingOption(event.target.value);
    };

    return (
        <div>
            <Button onClick={handleOpen}>Change user balance</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': { m: 1 },
                        }}
                    >
                        <TextField
                            value={changeMoney}
                            onChange={(e) =>
                                setChangeMoney(Number(e.target.value))
                            }
                            type={"number"}
                            helperText="Enter how much money change"
                            id="money-input"
                            label="Money"
                            sx={{flexBasis: '60%'}}
                        />
                        <TextField
                            value={changeCristal}
                            onChange={(e) =>
                                setChangeCristal(Number(e.target.value))
                            }
                            type={"number"}
                            helperText="Enter how much cristal change"
                            id="crystal-input"
                            label="Crystal"
                            sx={{flexBasis: '60%'}}
                        />
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="editingOption"
                            value={editingOption}
                            onChange={changeOptions}
                            helperText="select editing option"
                            sx={{flexBasis: '60%'}}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button sx={{flexBasis: '70%'}} onClick={ChangeUserBalance} size={'large'} variant="contained" endIcon={<SendIcon />}>
                            Change balance user-{userId}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}