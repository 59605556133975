import React, {useEffect} from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import {addSpaces, getHeaders, replaceCamelCase} from "../../../utils/utils";
import {BavariaEstatePlan} from "../../../types/DictionaryTypes";
import {
    deleteBavariaEstatePlan,
    getBavariaEstatePlan,
} from "../../../services/DictionaryService";
import DeleteIcon from '@mui/icons-material/Delete';
import {SortArrow} from "../../common/SortArrow";
import {Link} from "react-router-dom";
import GenerateBavariaEstate from "./modals/GenerateBavariaEstate";

interface BavariaEstateTableProps {
    reloadTables: boolean
}

export const BavariaEstateTable = ({reloadTables}: BavariaEstateTableProps) => {
    const [data, setData] = React.useState<BavariaEstatePlan[]>([])
    const [page, setPage] = React.useState(0)
    const [sortField, setSortField] = React.useState<string>("")
    const [limit, setLimit] = React.useState(5)
    const [offset, setOffset] = React.useState(0)
    const [sortDirection, setSortDirection] = React.useState<"DESC" | "">("")
    const [selectedField, setSelectedField] = React.useState<string>("")
    const [total, setTotal] = React.useState<number>(0)
    const [headers, setHeaders] = React.useState<Array<string>>([])
    const [reload, setReload] = React.useState<boolean>(true)

    useEffect(() => {
        (async () => {
            const result = await getBavariaEstatePlan({limit, offset, sortField, sortDirection})
            setData(result.list);
            setHeaders(getHeaders(result.list))
            setTotal(result.total.totalCount)
        })()
    }, [reloadTables, reload, limit, offset, sortField, sortDirection])

    function sortByField(field:string) {
        setSortField(replaceCamelCase(field))
        setSelectedField(field)
    }

    function directionDESC() {
        setSortDirection("DESC")
    }

    function directionDrop() {
        setSelectedField("")
        setSortField("")
        setSortDirection("")
    }

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
        setOffset(newPage * limit)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setLimit(parseInt(event.target.value, 10));
        setOffset(0)
    }

    function reloadThisTable() {
        reload ? setReload(false) : setReload(true);
    }

    return(
        <>
            <Box mt={"4em"} mb={"3em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>Bavaria estate-plan</Typography>
                <Typography variant={"h5"} component={"span"}>
                    {total}
                </Typography>
            </Box>
            <TableContainer component={Paper} sx={{mb: '3em'}}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers.map((field) => (
                                field !== "planSpend" ?
                                    <TableCell key={field} align="center" sx={{minWidth: "80px", padding: 0}}>
                                        <Button sx={{
                                            fontSize: "12px",
                                            height: "100%",
                                            width: "80%",
                                            padding: "15px",
                                            margin: 0
                                        }} color={"inherit"}
                                                onClick={() => sortByField(field)}>{addSpaces(field)}
                                        </Button>
                                        {selectedField === field
                                            ? <SortArrow field={field} directionDESC={directionDESC}
                                                         directionDrop={directionDrop}/>
                                            : ""
                                        }
                                    </TableCell>
                                    : <TableCell key={field} sx={{width: 0, height: 0}}></TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    DELETE DATA
                                </Typography>
                            </TableCell>
                            {data.length ?
                                <TableCell align="center">
                                    <GenerateBavariaEstate reload={reloadThisTable}/>
                                </TableCell>
                                : <></>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((values) => (
                            <TableRow
                                key={values.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                {Object.values(values).map((value: any, index) => (
                                    index !== 3 ?
                                        <TableCell key={index} align="center">{value}</TableCell>
                                        : <TableCell key={index} sx={{width: 0, height: 0}}></TableCell>
                                ))}
                                <TableCell align="center">
                                    <IconButton onClick={() => {deleteBavariaEstatePlan(values.id).then(reloadThisTable)}}>
                                        <DeleteIcon sx={{color: "#283593"}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <Link to={`/dictionary/bavaria/estate-plan-details/${values.id}`}>details</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <hr/>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                />
            </TableContainer>
        </>
    )
};