import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Login} from "../../services/LoginService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    borderRadius: '5%',
    boxShadow: 24,
    p: 4,
};

interface LoginModalProps {
    children: string;
}

export default function LoginModal({children}: LoginModalProps) {
    const [open, setOpen] = React.useState(false)
    const [login, setLogin] = React.useState<string>("")
    const [password, setPassword] = React.useState<string>("")

    function handleOpen() {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button sx={{color: "white"}} onClick={handleOpen}>
                Login
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <Typography variant={'h5'}>Login</Typography>
                        <TextField
                            value={login}
                            type={"text"}
                            onChange={(e) =>
                                setLogin(e.target.value)
                            }
                            helperText={'login'}
                            label={'login'}
                        />
                        <TextField
                            value={password}
                            onChange={(e) =>
                                setPassword(e.target.value)
                            }
                            helperText={'password'}
                            label={'password'}
                        />
                        <Button onClick={() => {
                            Login({login, password}).then(() => {handleClose(); document.location.reload()});
                        }} size={'large'} sx={{width: '57%'}} variant="contained">
                            Login
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}