import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField, Tooltip} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {QuestCondition} from "../../../../types/QuestTypes";
import ProductsList from "../../../products/modals/ProductsList";
import {Product} from "../../../../types/ProductTypes";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditConditionProps {
    values: QuestCondition;
    index: number;
    func: (newItem: QuestCondition, index: number) => void;
    products: Product[];
}

export default function EditCondition({values, func, index, products}: EditConditionProps) {
    const [open, setOpen] = React.useState(false)
    const [productId, setProductId] = React.useState<number>(1)
    const [count, setCount] = React.useState<number>(0)
    const [statisticsType, setStatisticsType] = React.useState<number>(0)

    function handleOpen() {
        setProductId(values.productId)
        setCount(values.count)
        setStatisticsType(values.statisticsType)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const changeProductId = (val: number) => {
        setProductId(val)
    }

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <Tooltip title={`Product id - ${productId}`}>
                            <Box sx={{flexBasis: '46%', flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                                <ProductsList currentId={productId} setId={changeProductId}  products={products}/>
                            </Box>
                        </Tooltip>
                        <TextField
                            value={count}
                            type={"number"}
                            onChange={(e) =>
                                setCount(Number(e.target.value))
                            }
                            helperText={'edit count'}
                            label={'count'}
                            sx={{flexBasis: '100%'}}
                        />
                        <TextField
                            value={statisticsType}
                            type={"number"}
                            onChange={(e) =>
                                setStatisticsType(Number(e.target.value))
                            }
                            helperText={'edit statisticsType'}
                            label={'statisticsType'}
                            sx={{flexBasis: '100%'}}
                        />
                        <Button onClick={() => {
                            func({productId, count, statisticsType}, index)
                            handleClose()
                        }
                        } sx={{flexBasis: '100%'}} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Change
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}