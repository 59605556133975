import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {ResponseList} from "../types/Types";
import {
    SortAndPagination,
} from "../types/ProductTypes";
import {Review} from "../types/ReviewsTypes";

export async function getAllReviews(filters:SortAndPagination): Promise<ResponseList<Review>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/reviews?${params}`, {method:"GET"});
    return await response.json();
}

export async function editReview(id: number, filters:Review): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/reviews/${id}`, {method:"PUT", body: params});
}

export async function deleteReview(id:number): Promise<void>  {
    await fetch(`/api/reviews/${id}`, {method: "DELETE"});
}
