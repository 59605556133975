import {SortAndPagination} from "../types/ProductTypes";
import {ResponseList} from "../types/Types";
import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {GenerateQuest, Quest} from "../types/QuestTypes";

export async function getAllQuests(filters:SortAndPagination): Promise<ResponseList<Quest>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/quests?${params}`, {method:"GET"});
    return await response.json();
}

export async function editQuest(id: number, filters:Quest): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/quests/${id}`, {method:"PUT", body: params});
}

export async function generateQuest(filters: GenerateQuest): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/quests`, {method: "POST", body: params});
}

export async function deleteQuest(id:number): Promise<void>  {
    await fetch(`/api/quests/${id}`, {method: "DELETE"});
}
