import React, {useEffect} from "react";
import {addSpaces, getHeaders} from "../../../utils/utils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {BavariaIndDict} from "../../../types/IndustryTypes";
import {deleteBavariaIndDict, getBavariaIndDict} from "../../../services/IndustryService";
import EditBavariaIndDict from "./modals/EditBavariaIndDict";
import GenerateBavariaIndDict from "./modals/GenerateBavariaInDict";

interface BavariaDictTableProps {
    reloadTables: boolean
}

export const BavariaDictTable = ({reloadTables}: BavariaDictTableProps) => {
    const [data, setData] = React.useState<BavariaIndDict[]>([])
    const [total, setTotal] = React.useState(0)
    const [headers, setHeaders] = React.useState([""])
    const [reload, setReload] = React.useState<boolean>(true)

    useEffect(() => {
        (async () => {
            const result = await getBavariaIndDict()
            setData(result.list);
            setHeaders(getHeaders(result.list))
            setTotal(result.total.totalCount)
        })()
    }, [reloadTables, reload])

    function reloadThisTable() {
        reload ? setReload(false) : setReload(true);
    }

    return(
        <>
            <Box mt={"4em"} mb={"3em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>Bavaria Dictionary</Typography>
                <Typography variant={"h5"} component={"span"}>
                    {total}
                </Typography>
            </Box>
            <TableContainer component={Paper} sx={{mb: '3em'}}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers.map((field) => (
                                <TableCell key={field} align="center" sx={{minWidth: "80px", padding: 0}}>
                                    <Button sx={{fontSize: "12px", height: "100%", width: "80%", padding: "15px", margin: 0}} color={"inherit"}>{addSpaces(field)}</Button>
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    EDIT DATA
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    DELETE DATA
                                </Typography>
                            </TableCell>
                            {data.length ?
                                <TableCell align="center">
                                    <GenerateBavariaIndDict reload={reloadThisTable}/>
                                </TableCell>
                                : <></>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((values, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                {Object.values(values).map((value: any, index) => (
                                    <TableCell key={index} align="center">{value}</TableCell>
                                ))}
                                <TableCell align="center">
                                    <EditBavariaIndDict reload={reloadThisTable} values={values} />
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => {deleteBavariaIndDict(values.id).then(reloadThisTable)}}>
                                        <DeleteIcon sx={{color: "#283593"}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}