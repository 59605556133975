import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {generateProduct} from "../../../services/ProductService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface GenerateProductProps {
    reload: () => void;
}

export default function GenerateProduct({reload}: GenerateProductProps) {
    const [open, setOpen] = React.useState(false)
    const [iconId, setIconId] = React.useState<number>(1)
    const [name, setName] = React.useState<string>("")
    const [basePrice, setBasePrice] = React.useState<number>(0)

    const handleOpen= () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <AddBoxIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={iconId}
                            type={"number"}
                            onChange={(e) =>
                                setIconId(Number(e.target.value))
                            }
                            label={'iconId'}
                            helperText={'product iconId'}
                        />
                        <TextField
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            helperText={'product name'}
                            label={'name'}
                        />
                        <TextField
                            value={basePrice}
                            type={"number"}
                            onChange={(e) =>
                                setBasePrice(Number(e.target.value))
                            }
                            label={'basePrice'}
                            helperText={'product price'}
                        />
                        <Button onClick={() => {
                            generateProduct({
                                iconId,
                                name,
                                basePrice,
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Generate
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}