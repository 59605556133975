import React from 'react'
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import {MenuItem, TextField} from "@mui/material";
import {LanguagesTable} from "../components/langauges/LangaugesTable";

const options = [
    {
        value: 1,
        label: 'ru',
    },
    {
        value: 2,
        label: 'en',
    },
    {
        value: 3,
        label: 'de',
    }
]

export const LanguagesPage = () => {
    const [loading, setLoading] = React.useState(false);
    const [reloadTables, setReloadTables] = React.useState<boolean>(false)
    const [lang, setLang] = React.useState(1)

    const reload = () => {
        setLoading(true);
        setTimeout(() => {
            if(reloadTables) {
                setReloadTables(false)
            } else {
                setReloadTables(true)
            }
            setLoading(false)
        }, 2000);
    }

    const changeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLang(Number(event.target.value));
    };

    // const getIdLang = (lang: string) => {
    //     let result: number = 0;
    //     switch (lang) {
    //         case "ru": result = 1;
    //             break;
    //         case "en": result = 2;
    //             break;
    //         case "de": result = 3;
    //     }
    //     console.log(result)
    //     console.log(lang)
    //     return result
    // }

    return(
        <>
            <Box sx={{
                display: "flex",
                mb: "2em",
                mt: "3em",
                maxHeight: "50px"
            }}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="languages"
                    value={lang}
                    defaultValue={lang}
                    onChange={changeOptions}
                    helperText="select language"
                    sx={{flexBasis: '10%', margin: "0px auto 0px 3em"}}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <LoadingButton
                    onClick={reload}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{pr: "30px", pl: "30px", margin: "0px 3em 0px auto"}}
                >
                    Reload
                </LoadingButton>
            </Box>
            <Box mt={"3em"} mb={"3em"}>
                <LanguagesTable lang={lang} reloadTables={reloadTables}/>
            </Box>
        </>
    )
};