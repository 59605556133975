import React, {useEffect} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container, Divider,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LanguageIcon from '@mui/icons-material/Language';
import CollectionsIcon from '@mui/icons-material/Collections';
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import usersImg from "../img/users.png";
import dictionaryImg from "../img/dictionary.png";
import questImg from "../img/quest.png";
import productsImg from "../img/products.png";
import recipesImg from "../img/recipes.png";
import langImg from "../img/lang.jpg";
import galleryImg from "../img/gallery.png";
import industryImg from "../img/industry.png";
import reviewImg from "../img/reviews.png";



const cardsCommon = [
    {id: 1, title: "Users", link: "/users", img: usersImg, icon: <PeopleIcon /> },
    {id: 2, title: "Products", link: "/products", img: productsImg, icon: <LocalGroceryStoreIcon /> },
    {id: 3, title: "Languages", link: "/languages", img: langImg, icon: <LanguageIcon /> },
    {id: 4, title: "Gallery", link: "/gallery", img: galleryImg, icon: <CollectionsIcon /> },
    {id: 5, title: "Recipes", link: "/recipes", img: recipesImg, icon: <LocalGroceryStoreIcon /> },
    {id: 6, title: "Reviews", link: "/reviews", img: reviewImg, icon: <PeopleIcon /> },
];

const cardsBavaria = [
    {id: 1, title: "Industry", link: "/bavaria/industry", img: industryImg, icon: <HolidayVillageIcon /> },
    {id: 2, title: "Quests", link: "/bavaria/quests", img: questImg, icon: <NewspaperIcon /> },
    {id: 3, title: "Recipes", link: "/bavaria/recipes", img: recipesImg, icon: <LocalGroceryStoreIcon /> },
    {id: 4, title: "Dictionary", link: "/bavaria/dictionary", img: dictionaryImg, icon: <QueryStatsIcon /> },
];

const cardsSchwarzerwald = [
    {id: 1, title: "Industry", link: "/schwarzerwald/industry", img: industryImg, icon: <HolidayVillageIcon /> },
    {id: 2, title: "Quests", link: "/schwarzerwald/quests", img: questImg, icon: <NewspaperIcon /> },
    {id: 3, title: "Recipes", link: "/schwarzerwald/recipes", img: recipesImg, icon: <LocalGroceryStoreIcon /> },
    {id: 4, title: "Dictionary", link: "/schwarzerwald/dictionary", img: dictionaryImg, icon: <QueryStatsIcon /> },
];

const options = [
    {
        value: 'Bavaria',
        label: 'Bavaria',
    },
    {
        value: 'Schwarzerwald',
        label: 'Schwarzerwald',
    },
]

const MainPage = () => {
    const [map, setMap] = React.useState('Bavaria');

    useEffect(() => {
        const selectedMap = localStorage.getItem('selectedMap')
        if (selectedMap != null) {
            setMap(selectedMap);
        }
    }, [])

    const changeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMap(event.target.value)
        localStorage.setItem('selectedMap', event.target.value)
    }

    const renderCardsForMap = () => {
        return (
           map === 'Bavaria' ?
               <Grid container spacing={4}>
                   {cardsBavaria.map((card => (
                       <Grid item key={card.id} xs={12} sm={6} md={3}>
                           <Card>
                               <CardMedia
                                   image= {card.img}
                                   title= {card.title}
                                   sx={{ paddingTop: "56.25%" }}
                               />
                               <CardContent sx={{flexGrow: 1}}>
                                   <Typography variant="h5" gutterBottom>
                                       {card.title}
                                   </Typography>
                               </CardContent>
                               <CardActions>
                                   <Button component={Link} to={card.link} size="small" color="primary">
                                       View
                                   </Button>
                                   <Box sx={{ ml: 'auto', mr: 1 }}>
                                       {card.icon}
                                   </Box>
                               </CardActions>
                           </Card>
                       </Grid>
                   )))}
               </Grid>
               : map === 'Schwarzerwald' ?
                   <Grid container spacing={4}>
                       {cardsSchwarzerwald.map((card => (
                           <Grid item key={card.id} xs={12} sm={6} md={3}>
                               <Card>
                                   <CardMedia
                                       image= {card.img}
                                       title= {card.title}
                                       sx={{ paddingTop: "56.25%" }}
                                   />
                                   <CardContent sx={{flexGrow: 1}}>
                                       <Typography variant="h5" gutterBottom>
                                           {card.title}
                                       </Typography>
                                   </CardContent>
                                   <CardActions>
                                       <Button component={Link} to={card.link} size="small" color="primary">
                                           View
                                       </Button>
                                       <Box sx={{ ml: 'auto', mr: 1 }}>
                                           {card.icon}
                                       </Box>
                                   </CardActions>
                               </Card>
                           </Grid>
                       )))}
                   </Grid>
               : null
        )
    }

    return (
        <>
            {document.cookie ?
            <Container maxWidth="lg" sx={{ mb: 3, mt: 3 }}>
                <Grid container spacing={4}>
                    {cardsCommon.map((card => (
                        <Grid item key={card.id} xs={12} sm={6} md={3}>
                            <Card>
                                <CardMedia
                                    image= {card.img}
                                    title= {card.title}
                                    sx={{ paddingTop: "56.25%" }}
                                />
                                <CardContent sx={{flexGrow: 1}}>
                                    <Typography variant="h5" gutterBottom>
                                        {card.title}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to={card.link} size="small" color="primary">
                                        View
                                    </Button>
                                    <Box sx={{ ml: 'auto', mr: 1 }}>
                                        {card.icon}
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    )))}
                </Grid>
                <Divider sx={{margin: '2em 0'}} />
                <TextField
                    id="outlined-select"
                    select
                    label="Map"
                    value={map}
                    onChange={changeOptions}
                    helperText="select map"
                    sx={{width: '100%', marginBottom: '2em'}}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                {renderCardsForMap()}
            </Container>
                : <Typography variant={'h4'} sx={{m: '1em 0 0 1em'}}>You need to login</Typography>}
        </>
    );
};

export default MainPage;