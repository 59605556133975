import React, {useEffect} from 'react';
import {
    Button, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {SortArrow} from "../../common/SortArrow";
import {addSpaces, getHeaders, replaceCamelCase} from "../../../utils/utils";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleteSchwRecipeSpend, getAllSchwRecipesSpend} from "../../../services/RecipesService";
import {RecipeSchwSpend} from "../../../types/RecipesTypes";
import GenerateSchwRecipeSpend from "./modals/GenerateSchwRecipeSpend";
import EditSchwRecipeSpend from "./modals/EditSchwRecipeSpend";
import {Product} from "../../../types/ProductTypes";


interface RecipesSpendSchwTableProps {
    reloadTables: boolean;
    products: Product[];
}

export const RecipesSpendSchwTable = ({reloadTables, products}: RecipesSpendSchwTableProps) => {
    const [recipesSpend, setRecipesSpend] = React.useState<RecipeSchwSpend[]>([])
    const [page, setPage] = React.useState(0)
    const [sortField, setSortField] = React.useState<string>("")
    const [limit, setLimit] = React.useState(5)
    const [offset, setOffset] = React.useState(0)
    const [sortDirection, setSortDirection] = React.useState<"DESC" | "">("")
    const [selectedField, setSelectedField] = React.useState<string>("")
    const [total, setTotal] = React.useState<number>(0)
    const [headers, setHeaders] = React.useState<Array<string>>([])
    const [reload, setReload] = React.useState<boolean>(true)

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            const result = await getAllSchwRecipesSpend({limit, offset, sortField, sortDirection})
            setRecipesSpend(result.list);
            setTotal(result.total.totalCount)
            setHeaders(getHeaders(result.list))
        })()
    }, [limit, offset, sortField, sortDirection, reloadTables, reload])
    /* eslint-enable */

    function sortByField(field:string) {
        setSortField(replaceCamelCase(field))
        setSelectedField(field)
    }

    function directionDESC() {
        setSortDirection("DESC")
    }

    function directionDrop() {
        setSelectedField("")
        setSortField("")
        setSortDirection("")
    }

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
        setOffset(newPage * limit)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setLimit(parseInt(event.target.value, 10));
        setOffset(0)
    }

    function reloadThisTable() {
        reload ? setReload(false) : setReload(true);
    }

    return (
        <>
            <Box mt={"3em"} mb={"3em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>All Schwarzerwald Recipes Spend</Typography>
                <Typography variant={"h5"} component={"span"}>
                    {total}
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers?.map((field) => (
                                <TableCell key={field} align="center" sx={{minWidth: "175px", padding: 0}}>
                                    {field === "productName" ?
                                        <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                            PRODUCT NAME
                                        </Typography> :
                                        <Button sx={{
                                            fontSize: "12px",
                                            height: "100%",
                                            width: "50%",
                                            padding: "15px",
                                            margin: 0
                                        }} color={"inherit"}
                                                onClick={() => sortByField(field)}>{addSpaces(field)}
                                        </Button>
                                    }
                                    {selectedField === field
                                        ? <SortArrow field={field} directionDESC={directionDESC}
                                                     directionDrop={directionDrop}/>
                                        : ""
                                    }
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    EDIT DATA
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    DELETE DATA
                                </Typography>
                            </TableCell>
                            {recipesSpend.length ?
                                <TableCell align="center">
                                    <GenerateSchwRecipeSpend products={products} reload={reloadThisTable} />
                                </TableCell>
                                : <></>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recipesSpend?.map((values, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                {Object.values(values).map((value: any, index) => (
                                    <TableCell key={index} align="center">{value}</TableCell>
                                ))}
                                <TableCell align="center">
                                    <EditSchwRecipeSpend products={products} values={values} reload={reloadThisTable}/>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => {deleteSchwRecipeSpend(values.id).then(reloadThisTable)}}>
                                        <DeleteIcon sx={{color: "#283593"}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <hr/>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                />
            </TableContainer>
        </>
    )
};