import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffff6b',
            main: '#fdd835',
            dark: '#c6a700',
            contrastText: '#000',
        },
    },
});