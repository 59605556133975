import React, {useEffect} from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {SortArrow} from "../components/common/SortArrow";
import {Link} from "react-router-dom";
import {addSpaces, getHeaders, replaceCamelCase} from "../utils/utils";
import {User} from "../types/UsersTypes";
import {getAllUsers} from "../services/UserService";


export const UsersPage = () => {
    const [users, setUsers] = React.useState<User[]>([])
    const [totalUsers, setTotalUsers] = React.useState(0)
    const [sortField, setSortField] = React.useState<string>("")
    const [loading, setLoading] = React.useState(false);
    const [limit, setLimit] = React.useState(5)
    const [page, setPage] = React.useState(0)
    const [offset, setOffset] = React.useState(0)
    const [headers, setHeaders] = React.useState([""])
    const [selectedField, setSelectedField] = React.useState("")
    const [sortDirection, setSortDirection] = React.useState<"DESC" | "ASC">("ASC")
    const [reloadTables, setReloadTables] = React.useState<boolean>(false)

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    } as const;

    useEffect(() => {
        (async () => {
            const result = await getAllUsers({limit, offset, sortField, sortDirection})
            setUsers(result.list);
            setHeaders(getHeaders(result.list))
            setTotalUsers(result.total.totalCount)
        })()
    }, [limit, offset, sortField, sortDirection, reloadTables])

    const reload = () => {
        setLoading(true);
        setTimeout(() => {
            if(reloadTables) {
                setReloadTables(false)
            } else {
                setReloadTables(true)
            }
            setLoading(false)
        }, 2000);
    }

    function sortByField(field:string) {
        setSortField(replaceCamelCase(field))
        setSelectedField(field)
    }

    function directionDESC() {
        setSortDirection("DESC")
    }

    function directionDrop() {
        setSelectedField("")
        setSortField("")
        setSortDirection("ASC")
    }

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
        setOffset(newPage * limit)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setLimit(parseInt(event.target.value, 10));
        setOffset(0)
    }

    function getDate(date: string) {
        const response = new Date(date)
        return response.toLocaleString("ru", options)
    }

    return(
        <>
            <Box mt={"2em"} mb={"1em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>All Users</Typography>
                <Typography variant={"h5"} component={"span"}>
                    {totalUsers}
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                mb: "2em"
            }}>
                <LoadingButton
                    onClick={reload}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{pr: "30px", pl: "30px"}}
                >
                    Reload
                </LoadingButton>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers.map((field) => (
                                <TableCell key={field} align="center" sx={{minWidth: "175px", padding: 0}}>
                                    <Button sx={{fontSize: "12px", height: "100%", width: "80%", padding: "15px", margin: 0}} color={"inherit"} onClick={() => sortByField(field)}>{addSpaces(field)}</Button>
                                    {selectedField === field
                                        ? <SortArrow field={field} directionDESC={directionDESC} directionDrop={directionDrop}/>
                                        : ""
                                    }
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>TRANSACTIONS</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow
                                key={user.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                <TableCell align="center">{user.id}</TableCell>

                                <TableCell align="center">
                                    {user.email.length ? user.email : "-"}
                                </TableCell>
                                <TableCell align="center">
                                    {user.facebookId.length ? user.facebookId : "-"}
                                </TableCell>
                                <TableCell align="center">
                                    {user.money > 0 ? user.money : 0}
                                </TableCell>
                                <TableCell align="center">
                                    {user.cristal > 0 ? user.cristal : 0}
                                </TableCell>
                                <TableCell align="center">
                                    {user.countTrade > 0 ? user.countTrade : 0}
                                </TableCell>
                                <TableCell align="center">
                                    {getDate(user.lastConnected)}
                                </TableCell>
                                <TableCell align="center">
                                    {getDate(user.lastDisconnected)}
                                </TableCell>
                                <TableCell align="center">
                                    {getDate(user.totalTime)}
                                </TableCell>
                                <TableCell align="center">
                                    <Link to={`/users/${user.id}/transactions`}>View</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <hr/>
                <TablePagination
                    component="div"
                    count={totalUsers}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                />
            </TableContainer>
        </>
    )
}