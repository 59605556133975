import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {Recipes} from "../../../types/RecipesTypes";
import {editRecipe} from "../../../services/RecipesService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditRecipeProps {
    values: Recipes;
    reload: () => void;
}

export default function EditRecipe({values, reload}: EditRecipeProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [dictionaryID, setDictionaryID] = React.useState<number>(0)
    const [iconID, setIconID] = React.useState<number>(1)
    const [entityID, setEntityID] = React.useState<number>(1)
    const [description, setDescription] = React.useState<string>('')
    const [name, setName] = React.useState<string>('')
    const [stage, setStage] = React.useState<number>(0)
    const [farmType, setFarmType] = React.useState<number>(0)

    function handleOpen() {
        setId(values.id)
        setDictionaryID(values.dictionaryID)
        setIconID(values.iconID)
        setEntityID(values.entityID)
        setDescription(values.description)
        setName(values.name)
        setStage(values.stage)
        setFarmType(values.farmType)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            type={"number"}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            helperText={'recipe id'}
                            label={'id'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={dictionaryID}
                            type={"number"}
                            onChange={(e) =>
                                setDictionaryID(Number(e.target.value))
                            }
                            helperText={'edit dictionaryID'}
                            label={'dictionaryID'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={iconID}
                            type={"number"}
                            onChange={(e) =>
                                setIconID(Number(e.target.value))
                            }
                            helperText={'iconID'}
                            label={'iconID'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={entityID}
                            type={"number"}
                            onChange={(e) =>
                                setEntityID(Number(e.target.value))
                            }
                            helperText={'entityID'}
                            label={'entityID'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={description}
                            type={"text"}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                            helperText={'edit description'}
                            label={'description'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={name}
                            type={"text"}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            helperText={'edit name'}
                            label={'name'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={stage}
                            type={"number"}
                            onChange={(e) =>
                                setStage(Number(e.target.value))
                            }
                            helperText={'edit stage'}
                            label={'stage'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={farmType}
                            type={"number"}
                            onChange={(e) =>
                                setFarmType(Number(e.target.value))
                            }
                            helperText={'edit farmType'}
                            label={'farmType'}
                            sx={{flexBasis: '46%'}}
                        />
                        <Button onClick={() => {
                            editRecipe(id, {id, farmType, dictionaryID, iconID, entityID, description, name, stage}).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}