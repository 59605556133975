import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    TextField, Tooltip
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {BavariaPlantDict} from "../../../../types/DictionaryTypes";
import {editBavariaPlantDict} from "../../../../services/DictionaryService";
import ProductsList from "../../../products/modals/ProductsList";
import {Product} from "../../../../types/ProductTypes";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditBavariaPlantDictProps {
    values: BavariaPlantDict;
    reload: () => void;
    products: Product[];
}

export default function EditBavariaPlantDict({values, reload, products}: EditBavariaPlantDictProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [tileType, setTileType] = React.useState<number>(0)
    const [name, setName] = React.useState<string>("")
    const [description, setDescription] = React.useState<string>("")
    const [bundle, setBundle] = React.useState<string>("")
    const [growTime, setGrowTime] = React.useState<number>(0)
    const [stages, setStages] = React.useState<number>(0)
    const [backstage, setBackstage] = React.useState<number>(0)
    const [price, setPrice] = React.useState<number>(0)
    const [productId, setProductId] = React.useState<number>(1)
    const [productCount, setProductCount] = React.useState<number>(0)



    function handleOpen() {
        setId(values.id)
        setTileType(values.tileType)
        setName(values.name)
        setDescription(values.description)
        setBundle(values.bundle)
        setGrowTime(values.growTime)
        setStages(values.stages)
        setBackstage(values.backstage)
        setPrice(values.price)
        setProductId(values.productId)
        setProductCount(values.productCount)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const changeProductId = (val: number) => {
        setProductId(val)
    }

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            type={"number"}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            helperText={'BavariaPlantDict id'}
                            label={'id'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={tileType}
                            type={"number"}
                            onChange={(e) =>
                                setTileType(Number(e.target.value))
                            }
                            helperText={'edit tile type'}
                            label={'tileType'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            helperText={'edit name'}
                            label={'name'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={bundle}
                            onChange={(e) =>
                                setBundle(e.target.value)
                            }
                            helperText={'edit bundle'}
                            label={'bundle'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={growTime}
                            type={"number"}
                            onChange={(e) =>
                                setGrowTime(Number(e.target.value))
                            }
                            helperText={'edit build time in seconds'}
                            label={'growTime'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={stages}
                            type={"number"}
                            onChange={(e) =>
                                setStages(Number(e.target.value))
                            }
                            helperText={'edit stages'}
                            label={'stages'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={backstage}
                            type={"number"}
                            onChange={(e) =>
                                setBackstage(Number(e.target.value))
                            }
                            helperText={'edit backstage'}
                            label={'backstage'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={price}
                            type={"number"}
                            onChange={(e) =>
                                setPrice(Number(e.target.value))
                            }
                            helperText={'edit price'}
                            label={'price'}
                            sx={{flexBasis: '46%'}}
                        />
                        <Tooltip title={`Product id - ${productId}`}>
                            <Box sx={{flexBasis: '46%', flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                                <ProductsList currentId={productId} setId={changeProductId}  products={products}/>
                            </Box>
                        </Tooltip>
                        <TextField
                            value={productCount}
                            type={"number"}
                            onChange={(e) =>
                                setProductCount(Number(e.target.value))
                            }
                            helperText={'production time in seconds'}
                            label={'productCount'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={description}
                            multiline
                            rows={3}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                            helperText={'edit description'}
                            label={'description'}
                            sx={{flexBasis: "96%"}}
                        />
                        <Button onClick={() => {
                            editBavariaPlantDict(id ,{
                                id,
                                tileType,
                                name,
                                description,
                                bundle,
                                growTime,
                                stages,
                                backstage,
                                price,
                                productId,
                                productCount,
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}