import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField, Tooltip} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {generateSchwRecipeGain} from "../../../../services/RecipesService";
import ProductsList from "../../../products/modals/ProductsList";
import {Product} from "../../../../types/ProductTypes";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface GenerateSchwRecipeGainProps {
    reload: () => void;
    products: Product[];
}

export default function GenerateSchwRecipeGain({reload, products}: GenerateSchwRecipeGainProps) {
    const [open, setOpen] = React.useState(false)
    const [schwarzerwaldRecipeId, setSchwarzerwaldRecipeId] = React.useState<number>(1)
    const [productId, setProductId] = React.useState<number>(1)
    const [count, setCount] = React.useState<number>(0)

    const handleOpen= () => setOpen(true);
    const handleClose = () => setOpen(false);

    const changeProductId = (val: number) => {
        setProductId(val)
    }


    return (
        <div>
            <IconButton onClick={handleOpen}>
                <AddBoxIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={schwarzerwaldRecipeId}
                            type={"number"}
                            onChange={(e) =>
                                setSchwarzerwaldRecipeId(Number(e.target.value))
                            }
                            label={'schwarzerwaldRecipeId'}
                            helperText={'schwarzerwaldRecipeId'}
                        />
                        <Tooltip title={`Product id - ${productId}`}>
                            <Box sx={{flexBasis: '46%', flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                                <ProductsList currentId={productId} setId={changeProductId}  products={products}/>
                            </Box>
                        </Tooltip>
                        <TextField
                            value={count}
                            type={"number"}
                            onChange={(e) =>
                                setCount(Number(e.target.value))
                            }
                            label={'count'}
                            helperText={'count'}
                        />
                        <Button onClick={() => {
                            generateSchwRecipeGain({
                                schwarzerwaldRecipeId,
                                productId,
                                count
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Generate
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}