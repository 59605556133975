import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBoxIcon from "@mui/icons-material/AddBox";
import {PlanSpend} from "../../../../types/DictionaryTypes";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface GeneratePlanSpendProps {
    func: (newItem: PlanSpend) => void;
}

export default function GeneratePlanSpend({func}: GeneratePlanSpendProps) {
    const [open, setOpen] = React.useState(false)
    const [bavariaEstatePlanId, setBavariaEstatePlanId] = React.useState<number>(1)
    const [productId, setProductId] = React.useState<number>(1)
    const [count, setCount] = React.useState<number>(0)

    function handleOpen() {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <AddBoxIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={bavariaEstatePlanId}
                            type={"number"}
                            helperText={'bavariaEstatePlanId'}
                            label={'bavariaEstatePlanId'}
                            onChange={(e) =>
                                setBavariaEstatePlanId(Number(e.target.value))
                            }
                            sx={{flexBasis: '100%'}}
                        />
                        <TextField
                            value={productId}
                            type={"number"}
                            helperText={'product id'}
                            label={'productId'}
                            onChange={(e) =>
                                setProductId(Number(e.target.value))
                            }
                            sx={{flexBasis: '100%'}}
                        />
                        <TextField
                            value={count}
                            type={"number"}
                            onChange={(e) =>
                                setCount(Number(e.target.value))
                            }
                            helperText={'count'}
                            label={'count'}
                            sx={{flexBasis: '100%'}}
                        />
                        <Button onClick={() => {
                            func({bavariaEstatePlanId, productId, count})
                            handleClose()
                        }
                        } sx={{flexBasis: '100%'}} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Add
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}