import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    TextField
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {generateSchwarzerwaldPlantDict} from "../../../../services/DictionaryService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface GenerateSchwarzerwaldPlantDictProps {
    reload: () => void;
}

export default function GenerateSchwarzerwaldPlantDict({reload}: GenerateSchwarzerwaldPlantDictProps) {
    const [open, setOpen] = React.useState(false)
    const [name, setName] = React.useState<string>("")
    const [description, setDescription] = React.useState<string>("")
    const [stages, setStages] = React.useState<number>(0)

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <AddBoxIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            label={'name'}
                            helperText={'name'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={stages}
                            type={"number"}
                            onChange={(e) =>
                                setStages(Number(e.target.value))
                            }
                            label={'stages'}
                            helperText={'stages'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={description}
                            multiline
                            rows={3}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                            helperText={'description'}
                            label={'description'}
                            sx={{flexBasis: "96%"}}
                        />
                        <Button onClick={() => {
                            generateSchwarzerwaldPlantDict({
                                name,
                                description,
                                stages,
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Post
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}