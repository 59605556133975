export function mapAnyToMapString(source: Record<string, any>): Record<string, string> {
    source = {...source}
    Object.keys(source).forEach(filterKey => {
        if (!source[filterKey]) {
            return;
        }

        if (source[filterKey].toJSON) {
            source[filterKey] = source[filterKey].toJSON();
            return;
        }

        source[filterKey] = source[filterKey].toString();
    });
    return source
}

export function removeEmptyFields<T>(obj: T): T {
    Object.keys(obj).forEach(key => {
        const v = (obj as any)[key];
        if (v === undefined || v === null || v === '') {
            delete (obj as any)[key];
        }
    });

    return obj;
}

export function replaceCamelCase(str: string) {
    let copyStr = str
    let capitalStr = str.toUpperCase()

    for(let i = 0; i < str.length; i++) {
        for(let j = 0; j < capitalStr.length; j++) {
            if(str[i] === capitalStr[j]) {
                copyStr = copyStr.replace(str[i], `_${str[i].toLowerCase()}`)
            }
        }
    }
    return copyStr
}

export function addSpaces(str: string) {
    let copyStr = str
    let capitalStr = str.toUpperCase()

    for(let i = 0; i < str.length; i++) {
        if (str[i] !== 'D' && str[i - 1] !== 'I') {
            for(let j = 0; j < capitalStr.length; j++) {
                if(str[i] === capitalStr[j]) {
                    copyStr = copyStr.replace(str[i], ` ${str[i].toLowerCase()}`)
                }
            }
        }
    }
    return copyStr
}

export function getHeaders(list: Array<any>) {
    return Object.keys(list[0])
}

// to remove non-editable fields
export function returnParams(filters: any, prevValue: any) {
    const filtersKeys = Object.getOwnPropertyNames(filters);
    let params: any = {id: filters.id}

    for (let i = 0; i < filtersKeys.length; i++) {
        const prop: any = filtersKeys[i];

        if (filters[prop] !== prevValue[prop]) {
            params[prop] = filters[prop]
        }
    }
    return params
}

/* eslint-disable no-useless-escape */
export function getCookie(name: string): string {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : '';
}
/* eslint-enable */