import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {ResponseList} from "../types/Types";
import {
    GenerateBavariaPlantDictType,
    BavariaPlantDict,
    DictionaryFilters,
    SchwarzerwaldPlantDict,
    GenerateSchwarzerwaldPlant,
    BavariaEstatePlan,
    GenerateBavariaEstatePlan, BavariaEstatePlanDetailsType
} from "../types/DictionaryTypes";

//Bavaria

export async function getBavariaPlantDict(): Promise<ResponseList<BavariaPlantDict>> {
    const response = await fetch(`/api/bavaria/plant-dictionary`, {method: "GET"});
    return await response.json();
}

export async function editBavariaPlantDict(id:number, filters:BavariaPlantDict): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/plant-dictionary/${id}`, {method: "PUT", body: params});
}

export async function generateBavariaPlantDict(filters: GenerateBavariaPlantDictType): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/plant-dictionary`, {method: "POST", body: params});
}

export async function deleteBavariaPlantDict(id: number): Promise<void>  {
    await fetch(`/api/bavaria/plant-dictionary/${id}`, {method: "DELETE"});
}

//Estate plan

export async function getBavariaEstatePlan(filters:DictionaryFilters): Promise<ResponseList<BavariaEstatePlan>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/bavaria/estate-plan?${params}`, {method: "GET"});
    return await response.json();
}

export async function getBavariaEstatePlanDetails(id: number): Promise<BavariaEstatePlanDetailsType> {
    const response = await fetch(`/api/bavaria/estate-plan-details/${id}`, {method: "GET"});
    return await response.json();
}

export async function editBavariaEstatePlan(id: number, filters:BavariaEstatePlan): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/estate-plan/${id}`, {method: "PUT", body: params});
}

export async function generateBavariaEstatePlan(filters: GenerateBavariaEstatePlan): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/estate-plan`, {method: "POST", body: params});
}

export async function deleteBavariaEstatePlan(id: number): Promise<void>  {
    await fetch(`/api/bavaria/estate-plan/${id}`, {method: "DELETE"});
}

//Schwarzerwald

export async function getSchwarzerwaldPlantDict(filters:DictionaryFilters): Promise<ResponseList<SchwarzerwaldPlantDict>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/schwarzerwald/plant-dictionary?${params}`, {method: "GET"});
    return await response.json();
}

export async function editSchwarzerwaldPlantDict(id: number, filters:SchwarzerwaldPlantDict): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/plant-dictionary/${id}`, {method: "PUT", body: params});
}

export async function generateSchwarzerwaldPlantDict(filters: GenerateSchwarzerwaldPlant): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/schwarzerwald/plant-dictionary`, {method: "POST", body: params});
}

export async function deleteSchwarzerwaldPlantDict(id: number): Promise<void>  {
    await fetch(`/api/schwarzerwald/plant-dictionary/${id}`, {method: "DELETE"});
}

