import {ResponseList} from "../types/Types";
import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {LangFilters, Languages} from "../types/LanguagesTypes";

export async function getTranslates(filters: LangFilters): Promise<ResponseList<Languages>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/languages?${params}`, {method:"GET"});
    return await response.json();
}

export async function editTranslates(id: number, filters: Languages): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/languages/${id}`, {method:"PUT", body: params});
}

export async function postTranslates(filters: Languages): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/languages`, {method: "POST", body: params});
}

export async function deleteTranslates(id: number, filters: Languages): Promise<void>  {
    let params = JSON.stringify(filters)
    await fetch(`/api/languages/${id}`, {method: "DELETE", body: params});
}