import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {generateBavariaIndDict} from "../../../../services/IndustryService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface GenerateBavariaIndDictProps {
    reload: () => void;
}

export default function GenerateBavariaIndDict({reload}: GenerateBavariaIndDictProps) {
    const [open, setOpen] = React.useState(false)
    const [tileType, setTileType] = React.useState<number>(0)
    const [buildDelay, setBuildDelay] = React.useState<number>(0)

    const handleOpen= () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <AddBoxIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={tileType}
                            type={'number'}
                            onChange={(e) =>
                                setTileType(Number(e.target.value))
                            }
                            label={'tileType'}
                            helperText={'tileType'}
                        />
                        <TextField
                            value={buildDelay}
                            type={'number'}
                            onChange={(e) =>
                                setBuildDelay(Number(e.target.value))
                            }
                            label={'buildDelay'}
                            helperText={'buildDelay'}
                        />
                        <Button onClick={() => {
                            generateBavariaIndDict({
                                tileType,
                                buildDelay
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Generate
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}