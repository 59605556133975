import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    TextField
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {SchwarzerwaldPlantDict} from "../../../../types/DictionaryTypes";
import {editSchwarzerwaldPlantDict} from "../../../../services/DictionaryService";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditSchwarzerwaldPlantDictProps {
    values: SchwarzerwaldPlantDict;
    reload: () => void;
}

export default function EditSchwarzerwaldPlantDict({values, reload}: EditSchwarzerwaldPlantDictProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [name, setName] = React.useState<string>("")
    const [description, setDescription] = React.useState<string>("")
    const [stages, setStages] = React.useState<number>(0)

    useEffect(() => {

    }, [])

    function handleOpen() {
        setId(values.id)
        setName(values.name)
        setDescription(values.description)
        setStages(values.stages)

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            type={"number"}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            helperText={'SchwarzerwaldPlantDict id'}
                            label={'id'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            helperText={'edit name'}
                            label={'name'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={stages}
                            type={"number"}
                            onChange={(e) =>
                                setStages(Number(e.target.value))
                            }
                            helperText={'edit stages'}
                            label={'stages'}
                            sx={{flexBasis: '96%'}}
                        />
                        <TextField
                            value={description}
                            multiline
                            rows={3}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                            helperText={'edit description'}
                            label={'description'}
                            sx={{flexBasis: "96%"}}
                        />

                        <Button onClick={() => {
                            editSchwarzerwaldPlantDict(id,{
                                id,
                                name,
                                description,
                                stages,
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}