import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {Quest, QuestCondition} from "../../../../types/QuestTypes";
import {editQuest} from "../../../../services/QuestService";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {addSpaces} from "../../../../utils/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCondition from "./EditCondition";
import GenerateCondition from "./GenerateCondition";
import {Product} from "../../../../types/ProductTypes";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditQuestProps {
    values: Quest;
    reload: () => void;
    products: Product[];
}

export default function EditQuest({values, reload, products}: EditQuestProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [reward, setReward] = React.useState<string>("")
    const [task, setTask] = React.useState<string>("")
    const [assistText, setAssistText] = React.useState<string>("")
    const [assistX, setAssistX] = React.useState<number>(0)
    const [assistY, setAssistY] = React.useState<number>(0)

    const [condition, setCondition] = React.useState<Array<QuestCondition>>([])
    const [headers, setHeaders] = React.useState<Array<string>>([])

    useEffect(() => {

    }, [condition])

    function handleOpen() {
        //const json: QuestCondition[] = JSON.parse(values.condition)
        setId(values.id)
        setHeaders(["productId", "count", "statisticsType"])
        setCondition(JSON.parse(String(values.condition)))
        setReward(values.reward)
        setTask(values.task)
        setAssistText(values.assistText)
        setAssistX(values.assistX)
        setAssistY(values.assistY)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const buildCondition = (condition: QuestCondition[]): string => {
        let result: string = '';
        const lastIndex = condition.length - 1
        if (condition.length <= 1) {
            result = `{\n    \"productId\": ${condition[0]?.productId},\n    \"count\": ${condition[0]?.count},\n    \"statisticsType\": ${condition[0]?.statisticsType}\n  }\n` // eslint-disable-line
        } else {
            result = `{\n    \"productId\": ${condition[0]?.productId},\n    \"count\": ${condition[0]?.count},\n    \"statisticsType\": ${condition[0]?.statisticsType}\n  },\n` // eslint-disable-line
        }
        if (condition.length > 1) {
            condition.forEach((item, index) => {
                if (index >= 1 && index !== lastIndex) {
                    result = result + `  {\n    \"productId\": ${item.productId},\n    \"count\": ${item.count},\n    \"statisticsType\": ${item.statisticsType}\n  },\n` // eslint-disable-line
                } else if (index === lastIndex) {
                    result = result + `  {\n    \"productId\": ${item.productId},\n    \"count\": ${item.count},\n    \"statisticsType\": ${item.statisticsType}\n  }\n` // eslint-disable-line
                }
            })
        }

        return "[\n  " + result + "]"
    }

    function deleteCondition(index: number) {
        setCondition(condition.filter((item, indexF) => indexF !== index ))
    }

    function editCondition(newItem: QuestCondition, index: number) {
        let newArr: QuestCondition[] = condition.concat()
        newArr[index] = newItem
        setCondition(newArr)
    }

    function postCondition(newItem: QuestCondition) {
        if (condition === null) {
            let newArr: QuestCondition[] = []
            newArr.push(newItem)
            setCondition(newArr)
        } else {
            let newArr: QuestCondition[] = condition.concat()
            newArr.push(newItem)
            setCondition(newArr)
        }
    }


    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            helperText={'quest id'}
                            label={'id'}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            sx={{flexBasis: '96%'}}
                        />
                        <TextField
                            value={task}
                            type={"text"}
                            onChange={(e) =>
                                setTask(e.target.value)
                            }
                            helperText={'edit task'}
                            label={'task'}
                            multiline
                            rows={5}
                            sx={{flexBasis: '96%'}}
                        />
                        <TextField
                            value={reward}
                            onChange={(e) =>
                                setReward(e.target.value)
                            }
                            helperText={'edit reward'}
                            label={'reward'}
                            sx={{flexBasis: '47%'}}
                        />
                        <TextField
                            value={assistText}
                            type={"text"}
                            onChange={(e) =>
                                setAssistText(e.target.value)
                            }
                            helperText={'edit assistText'}
                            label={'assistText'}
                            sx={{flexBasis: '47%'}}
                        />
                        <TextField
                            value={assistX}
                            type={"number"}
                            onChange={(e) =>
                                setAssistX(Number(e.target.value))
                            }
                            helperText={'edit assistX'}
                            label={'assistX'}
                            sx={{flexBasis: '47%'}}
                        />
                        <TextField
                            value={assistY}
                            type={"number"}
                            onChange={(e) =>
                                setAssistY(Number(e.target.value))
                            }
                            helperText={'edit assistY'}
                            label={'assistY'}
                            sx={{flexBasis: '47%'}}
                        />
                        <Typography variant={"h5"} component={"h5"} align={'left'} sx={{width: "100%"}}>Condition</Typography>
                        <TableContainer component={Paper} sx={{mb: '3em', maxHeight: 210}}>
                            <Table sx={{minWidth: 650, width: "100%"}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        {headers.map((field) => (
                                            <TableCell key={field} align="center"
                                                       sx={{minWidth: "50px", padding: 0}}>
                                                <Button sx={{
                                                    fontSize: "10px",
                                                    height: "100%",
                                                    width: "80%",
                                                    padding: "15px",
                                                    margin: 0
                                                }} color={"inherit"}>{addSpaces(field)}</Button>
                                            </TableCell>
                                        ))}
                                        <TableCell align="center">
                                            <Typography sx={{fontSize: "10px", fontWeight: 500}}
                                                        color={"inherit"}>
                                                EDIT DATA
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{fontSize: "10px", fontWeight: 500}}
                                                        color={"inherit"}>
                                                DELETE DATA
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <GenerateCondition products={products} func={postCondition}/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {condition !== null ?
                                        condition.map((values: QuestCondition, index: number) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {border: 0},
                                                    textDecoration: "none"
                                                }}
                                            >
                                                {Object.values(values).map((value: any, index) => (
                                                    <TableCell key={index} align="center">{value}</TableCell>
                                                ))}
                                                <TableCell align="center">
                                                    <EditCondition products={products} func={editCondition} values={values}
                                                                   index={index}/>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton onClick={() => deleteCondition(index)}>
                                                        <DeleteIcon sx={{color: "#283593"}}/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        ))
                                        : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button onClick={() => {
                            //let condition = buildCondition()
                            editQuest(id, {id, condition:  buildCondition(condition), reward, task, assistText, assistX, assistY}).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}