import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {editBavRecipe} from "../../../../services/RecipesService";
import {RecipeBav} from "../../../../types/RecipesTypes";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditBavRecipeProps {
    values: RecipeBav;
    reload: () => void;
}

export default function EditBavRecipe({values, reload}: EditBavRecipeProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [bavariaIndustryDictionaryId, setBavariaIndustryDictionaryId] = React.useState<number>(0)
    const [iconID, setIconID] = React.useState<number>(1)
    const [description, setDescription] = React.useState<string>('')
    const [name, setName] = React.useState<string>('')
    const [delay, setDelay] = React.useState<number>(0)

    function handleOpen() {
        setId(values.id)
        setBavariaIndustryDictionaryId(values.bavariaIndustryDictionaryId)
        setIconID(values.iconID)
        setDescription(values.description)
        setName(values.name)
        setDelay(values.delay)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            type={"number"}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            helperText={'recipe id'}
                            label={'id'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={bavariaIndustryDictionaryId}
                            type={"number"}
                            onChange={(e) =>
                                setBavariaIndustryDictionaryId(Number(e.target.value))
                            }
                            helperText={'edit bavaria industry dictionary id'}
                            label={'bavariaIndustryDictionaryId'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={iconID}
                            type={"number"}
                            onChange={(e) =>
                                setIconID(Number(e.target.value))
                            }
                            helperText={'iconID'}
                            label={'iconID'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={description}
                            type={"text"}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                            helperText={'edit description'}
                            label={'description'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={name}
                            type={"text"}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            helperText={'edit name'}
                            label={'name'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={delay}
                            type={"number"}
                            onChange={(e) =>
                                setDelay(Number(e.target.value))
                            }
                            helperText={'edit delay'}
                            label={'delay'}
                            sx={{flexBasis: '46%'}}
                        />
                        <Button onClick={() => {
                            editBavRecipe(id, {id, bavariaIndustryDictionaryId, iconID, description, name, delay}).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}