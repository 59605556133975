import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {addSpaces} from "../../../../utils/utils";
import {BavariaEstatePlan, PlanSpend} from "../../../../types/DictionaryTypes";
import {editBavariaEstatePlan} from "../../../../services/DictionaryService";
import Typography from "@mui/material/Typography";
import GeneratePlanSpend from "./GeneratePlanSpend";
import EditPlanSpend from "./EditPlanSpend";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditBavariaEstateProps {
    values: BavariaEstatePlan;
    reload: () => void;
}

export default function EditBavariaEstate({values, reload}: EditBavariaEstateProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState<number>(1)
    const [iconID, setIconID] = React.useState<number>(1)
    const [description, setDescription] = React.useState<string>('')
    const [name, setName] = React.useState<string>('')
    const [bavariaEstateDictionaryId, setBavariaEstateDictionaryId] = React.useState<number>(0)
    const [stages, setStages] = React.useState<number>(0)

    const [planSpend, setPlanSpend] = React.useState<Array<PlanSpend>>([])
    const [headers, setHeaders] = React.useState<Array<string>>([])

    useEffect(() => {

    }, [planSpend])

    function handleOpen() {
        setId(values.id)
        setBavariaEstateDictionaryId(values.bavariaEstateDictionaryId)
        setStages(values.stages)
        setIconID(values.iconID)
        setDescription(values.description)
        setName(values.name)
        setHeaders(["bavariaEstatePlanId", "productId", "count"])
        setPlanSpend((values.planSpend))
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    function deletePlanSpend(index: number) {
        setPlanSpend(planSpend.filter((item, indexF) => indexF !== index ))
    }

    function editPlanSpend(newItem: PlanSpend, index: number) {
        let newArr: PlanSpend[] = planSpend.concat()
        newArr[index] = newItem
        setPlanSpend(newArr)
    }

    function postPlanSpend(newItem: PlanSpend) {
        if (planSpend === null) {
            let newArr: PlanSpend[] = []
            newArr.push(newItem)
            setPlanSpend(newArr)
        } else {
            let newArr: PlanSpend[] = planSpend.concat()
            newArr.push(newItem)
            setPlanSpend(newArr)
        }
    }

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={id}
                            type={"number"}
                            onChange={(e) =>
                                setId(Number(e.target.value))
                            }
                            helperText={'BavariaEstatePlan id'}
                            label={'id'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={iconID}
                            type={"number"}
                            onChange={(e) =>
                                setIconID(Number(e.target.value))
                            }
                            helperText={'BavariaEstatePlan iconID'}
                            label={'iconID'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={bavariaEstateDictionaryId}
                            type={"number"}
                            onChange={(e) =>
                                setBavariaEstateDictionaryId(Number(e.target.value))
                            }
                            helperText={'edit bavariaEstateDictId'}
                            label={'bavariaEstateDictId'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={stages}
                            type={"number"}
                            onChange={(e) =>
                                setStages(Number(e.target.value))
                            }
                            helperText={'edit stages'}
                            label={'stages'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={description}
                            type={"text"}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                            helperText={'edit description'}
                            label={'description'}
                            sx={{flexBasis: '46%'}}
                        />
                        <TextField
                            value={name}
                            type={"text"}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            helperText={'edit name'}
                            label={'name'}
                            sx={{flexBasis: '46%'}}
                        />
                        <Typography sx={{m: '3em auto'}}>
                            Plan Spend
                        </Typography>
                        <TableContainer component={Paper} sx={{mb: '3em', maxHeight: 210}}>
                            <Table sx={{minWidth: 650, width: "100%"}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        {headers.map((field) => (
                                            <TableCell key={field} align="center"
                                                       sx={{minWidth: "50px", padding: 0}}>
                                                <Button sx={{
                                                    fontSize: "10px",
                                                    height: "100%",
                                                    width: "80%",
                                                    padding: "15px",
                                                    margin: 0
                                                }} color={"inherit"}>{addSpaces(field)}</Button>
                                            </TableCell>
                                        ))}
                                        <TableCell align="center">
                                            <Typography sx={{fontSize: "10px", fontWeight: 500}}
                                                        color={"inherit"}>
                                                EDIT DATA
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{fontSize: "10px", fontWeight: 500}}
                                                        color={"inherit"}>
                                                DELETE DATA
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <GeneratePlanSpend func={postPlanSpend}/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {planSpend !== null ?
                                        planSpend?.map((values, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {border: 0},
                                                    textDecoration: "none"
                                                }}
                                            >
                                                {Object.values(values).map((value: any, index) => (
                                                    <TableCell key={index} align="center">{value}</TableCell>
                                                ))}
                                                <TableCell align="center">
                                                    <EditPlanSpend func={editPlanSpend} values={values}
                                                                      index={index}/>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton onClick={() => deletePlanSpend(index)}>
                                                        <DeleteIcon sx={{color: "#283593"}}/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        ))
                                        : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button onClick={() => {
                            editBavariaEstatePlan(id,{
                                id,
                                bavariaEstateDictionaryId,
                                stages,
                                iconID,
                                description,
                                name,
                                planSpend
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}