import {SortAndPagination} from "../types/ProductTypes";
import {ResponseList} from "../types/Types";
import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {GalleryImg, GalleryImgGen} from "../types/GalleryTypes";

export async function getGalleryList(filters:SortAndPagination): Promise<ResponseList<GalleryImg>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/gallery?${params}`, {method:"GET"});
    return await response.json();
}

export async function editGalleryImg(id: number, filters:GalleryImg): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/gallery/${id}`, {method:"PUT", body: params});
}

export async function genGalleryImg(filters: GalleryImgGen): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/gallery`, {method: "POST", body: params});
}

export async function deleteImgFromGallery(id:number): Promise<void>  {
    await fetch(`/api/gallery/${id}`, {method: "DELETE"});
}