import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";
import {
        ListTransIn,
        ListTransOut, MoneyFilters,
        ResponseTransactions,
        TotalTransIn, TotalTransOut,
        TransactionsFilter
} from "../types/TransactionsTypes";

export async function getTransactionsIn(filters:TransactionsFilter, id: number): Promise<ResponseTransactions<ListTransIn, TotalTransIn>> {
        let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
        const response = await fetch(`/api/transactions/in/${id}?${params}`, {method: "GET"});
        return await response.json();
}

export async function getTransactionsOut(filters:TransactionsFilter, id: number): Promise<ResponseTransactions<ListTransOut, TotalTransOut>> {
        let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
        const response = await fetch(`/api/transactions/out/${id}?${params}`, {method: "GET"});
        return await response.json();
}

export async function changeMoneyForUser(id: number, filters:MoneyFilters): Promise<void> {
        let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
        await fetch(`/api/users/money/${id}?${params}`, {method: "PUT"});
}

