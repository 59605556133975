import React from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import {useParams} from "react-router-dom";
import TransactionModal from "../components/users/transactions/TransactionModal";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import {parseISO} from "date-fns";
import TextField from "@mui/material/TextField";
import {TransactionsTable} from "../components/users/transactions/TransactionsTable";
import {getTransactionsIn, getTransactionsOut} from "../services/TransactionsService";
import {User} from "../types/UsersTypes";

export const TransactionsPage = () => {
    const [userInfo, setUserInfo] = React.useState<User[]>([])
    const [loading, setLoading] = React.useState(false)
    const [dateFrom, setDateFrom] = React.useState<Date | null>(null)
    const [dateTo, setDateTo] = React.useState<Date | null>(null)
    const [reloadTables, setReloadTables] = React.useState<boolean>(false)
    const userId = (Number(useParams().id))

    const reload = React.useCallback(() => {
        setLoading(true);
        setTimeout(async () => {
            const transIn = await getTransactionsIn({}, userId)
            setUserInfo(transIn.userInfo)
            setReloadTables(true)
            setLoading(false)
        }, 2000);
    }, [userId])

    return(
        <>
            <Box m={5}>
                <TransactionModal reload={reload} userId={Number(userId)}/>
            </Box>
            <Box mt={"2em"} mb={"7px"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display:"flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"} >User id</Typography>
                <Typography variant={"h5"} component={"span"}>{userId}</Typography>
            </Box>
            <Box mb={"1em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display:"flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"} >User email</Typography>
                <Typography variant={"h5"} component={"span"}>
                        {userInfo[0]?.email.length ? userInfo[0]?.email : "-"}
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                mb: "2em"
            }}>
                <LoadingButton
                    onClick={reload}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{pr: "30px", pl: "30px"}}
                >
                    Reload
                </LoadingButton>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-around",
                mr: "auto",
                ml: "auto",
                mt: "50px",
                width: {
                    xl: "30%",
                    lg: "50%",
                    md: "70%",
                    sm: "80%",
                }
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        minDate={parseISO('2022-01-19')}
                        label="dateFrom"
                        value={dateFrom}
                        onChange={(newValue) => {
                            setDateFrom(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        minDate={parseISO('2022-01-19')}
                        label="dateTo"
                        value={dateTo}
                        onChange={(newValue) => {
                            setDateTo(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box mb={"3em"} mt={"3em"} mr={"auto"} ml={"auto"} sx={{width: "50%"}}>
                <Typography mb={"1em"} mt={"1em"} align={"center"} variant={"h4"}>
                    Transaction In
                </Typography>
                <TransactionsTable
                    reloadTables={reloadTables}
                    userId={userId}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    pagination={false}
                    getData={getTransactionsIn}
                />
            </Box>
            <Box mb={"3em"} mt={"3em"}>
                <TransactionsTable
                    reloadTables={reloadTables}
                    userId={userId}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    pagination={true}
                    getData={getTransactionsIn}
                    propsHeaders={["id", "userId", "externalServiceId", "externalService", "price", "coins", "date"]}
                />
            </Box>
            <Box mb={"3em"} mt={"3em"} mr={"auto"} ml={"auto"} sx={{width: "50%"}}>
                <Typography mb={"1em"} mt={"1em"} align={"center"} variant={"h4"}>
                    Transaction Out
                </Typography>
                <TransactionsTable
                    reloadTables={reloadTables}
                    userId={userId}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    pagination={false}
                    getData={getTransactionsOut}
                />
            </Box>
            <Box mb={"3em"} mt={"3em"}>
                <TransactionsTable
                    reloadTables={reloadTables}
                    userId={userId}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    pagination={true}
                    getData={getTransactionsOut}
                    propsHeaders={["coins", "count", "date", "id", "productId", "userId"]}
                />
            </Box>
        </>
    )
}