import {ResponseList} from "../types/Types";
import {
    BavariaBuildingSpend,
    BavariaIndDict,
    BavBuildingSpendPost,
    GenerateBavariaIndDict
} from "../types/IndustryTypes";
import {DictionaryFilters} from "../types/DictionaryTypes";
import {mapAnyToMapString, removeEmptyFields} from "../utils/utils";

export async function getBavariaIndDict(): Promise<ResponseList<BavariaIndDict>> {
    const response = await fetch(`/api/bavaria/industry-dictionary`, {method: "GET"});
    return await response.json();
}

export async function editBavariaIndDict(id:number, filters:BavariaIndDict): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/industry-dictionary/${id}`, {method: "PUT", body: params});
}

export async function generateBavariaIndDict(filters:GenerateBavariaIndDict): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/industry-dictionary`, {method: "POST", body: params});
}

export async function deleteBavariaIndDict(id: number): Promise<void>  {
    await fetch(`/api/bavaria/industry-dictionary/${id}`, {method: "DELETE"});
}

//bavaria building spend

export async function getBavariaBuildSpend(filters:DictionaryFilters): Promise<ResponseList<BavariaBuildingSpend>> {
    let params = new URLSearchParams(mapAnyToMapString(removeEmptyFields(filters)))
    const response = await fetch(`/api/bavaria/building-spend?${params}`, {method: "GET"});
    return await response.json();
}

export async function editBavariaBuildSpend(id:number, filters:BavariaBuildingSpend): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/building-spend/${id}`, {method: "PUT", body: params});
}

export async function generateBavariaBuildSpend(filters:BavBuildingSpendPost): Promise<void> {
    let params = JSON.stringify(filters)
    await fetch(`/api/bavaria/building-spend`, {method: "POST", body: params});
}

export async function deleteBavariaBuildSpend(id: number): Promise<void>  {
    await fetch(`/api/bavaria/building-spend/${id}`, {method: "DELETE"});
}