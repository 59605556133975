import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {IconsProduct} from "../../../types/ProductTypes";
import {editIcon} from "../../../services/ProductService";
import {returnParams} from "../../../utils/utils";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface EditIconProps {
    values: IconsProduct;
    reload: () => void;
}

export default function EditIcon({values, reload}: EditIconProps) {
    const [open, setOpen] = React.useState(false)
    const [iconId, setIconId] = React.useState<number>(1)
    const [url, setUrl] = React.useState<string>("")


    function handleOpen() {
        setIconId(values.id)
        setUrl(values.url)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={iconId}
                            type={"number"}
                            onChange={(e) =>
                                setIconId(Number(e.target.value))
                            }
                            helperText={'icon id'}
                            label={'id'}
                        />
                        <TextField
                            value={url}
                            onChange={(e) =>
                                setUrl(e.target.value)
                            }
                            helperText={'edit url'}
                            label={'url'}
                        />
                        <Button onClick={() => {
                            editIcon(iconId, returnParams({url}, values)).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}