import React, {useEffect} from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {addSpaces} from "../../utils/utils";
import {BavariaEstatePlan, PlanSpend} from "../../types/DictionaryTypes";
import {
    getBavariaEstatePlanDetails,
} from "../../services/DictionaryService";
import {Link, useParams} from "react-router-dom";
import EditBavariaEstate from "../../components/bavaria/dictionary/modals/EditBavariaEstate";

export const BavariaEstatePlanDetails = () => {
    const [data, setData] = React.useState<BavariaEstatePlan>()
    const [headers, setHeaders] = React.useState<Array<string>>([])
    const [reload, setReload] = React.useState<boolean>(true)
    const plantId = (Number(useParams().id))

    const [planSpend, setPlanSpend] = React.useState<PlanSpend[]>([])

    useEffect(() => {
        (async () => {
            const result = await getBavariaEstatePlanDetails(plantId)
            setData(result.list);
            setPlanSpend(result.list.planSpend)
            setHeaders(Object.keys(result.list))
        })()
    }, [reload, plantId])

    function reloadThisTable() {
        reload ? setReload(false) : setReload(true);
    }

    return (
        <>
            <Box mt={"4em"} mb={"3em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Button variant={"contained"} component={Link} sx={{pr: "30px", pl: "30px"}} to={"/dictionary/"}>Back</Button>
            </Box>
            <Box mt={"2em"} mb={"2em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>Bavaria estate-plan </Typography>
            </Box>
            <TableContainer component={Paper} sx={{mb: '3em'}}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers.map((field) => (
                                <TableCell key={field} align="center" sx={{minWidth: "80px", padding: 0}}>
                                    <Button sx={{fontSize: "12px", height: "100%", width: "80%", padding: "15px", margin: 0}} color={"inherit"}>
                                        {addSpaces(field)}
                                    </Button>
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    EDIT DATA
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                        >
                            {
                                data !== undefined ?
                                    <>
                                        <TableCell align="center">{data.id}</TableCell>
                                        <TableCell align="center">{data.bavariaEstateDictionaryId}</TableCell>
                                        <TableCell align="center">{data.stages}</TableCell>
                                        <TableCell align="center">{data.name}</TableCell>
                                        <TableCell align="center">{data.description}</TableCell>
                                        <TableCell align="center">{data.iconID}</TableCell>
                                        <TableCell sx={{width: "40%", border: "1px solid #dedede !important", p: 0}}>
                                            <Box sx={{display: "flex", width: "100%", borderBottom: "1px solid #dedede !important"}}>
                                                <Box sx={{textAlign: "center",  m: "5px 0", width: "33%", fontWeight: "500", fontSize: "12px"}}>BAVARIA ESTATE PLAN ID</Box>
                                                <Box sx={{textAlign: "center",  m: "5px 0", width: "33%", fontWeight: "500", fontSize: "12px"}}>PRODUCT ID</Box>
                                                <Box sx={{textAlign: "center",  m: "5px 0", width: "33%", fontWeight: "500", fontSize: "12px"}}>COUNT</Box>
                                            </Box>
                                            {planSpend.map((item: PlanSpend, index: number) => (
                                                <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}} key={index}>
                                                    <Box sx={{textAlign: "center", display: "flex", width: "33%", m: "5px 0", justifyContent: "center" }}>{item.bavariaEstatePlanId}</Box>
                                                    <Box sx={{textAlign: "center", display: "flex", width: "33%", m: "5px 0", justifyContent: "center" }}>{item.productId}</Box>
                                                    <Box sx={{textAlign: "center", display: "flex", width: "33%", m: "5px 0", justifyContent: "center" }}>{item.count}</Box>
                                                </Box>
                                            ))
                                            }
                                        </TableCell>
                                    </>
                                    : <></>
                            }
                            <TableCell align="center">
                                <EditBavariaEstate reload={reloadThisTable} values={data!} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};