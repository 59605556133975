import React from 'react';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {IconButton} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface SortArrowProps {
    directionDESC: (field:string) => void;
    directionDrop: (field:string) => void;
    field: string
}

export const SortArrow = ({directionDESC, directionDrop, field}:SortArrowProps) => {
    const [direction, setDirection] = React.useState<boolean>(true)

    const SortDirection = () => {
        switch (direction) {
            case true: setDirection(false)
                break;
            case false: setDirection(true)
                break;
        }
    }

    return (
        <>
            {direction
                    ? <IconButton onClick={() => {directionDESC(field); SortDirection();}}>
                        <ArrowDownwardIcon sx={{fontSize: "16px", color: "black"}}/>
                    </IconButton>
                    : <IconButton onClick={() => {directionDrop("");SortDirection();}}>
                        <ArrowUpwardIcon sx={{fontSize: "16px", color: "black"}}/>
                    </IconButton>
            }
        </>
    );
};
