import React, {useMemo, useState} from 'react';
import Header from "./components/common/Header";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import MainPage from "./pages/MainPage";
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import {UsersPage} from "./pages/UsersPage";
import {IndustryBavPage} from "./pages/bavaria/IndustryBavPage";
import {QuestsBavPage} from "./pages/bavaria/QuestsBavPage";
import {ProductsPage} from "./pages/ProductsPage";
import {DictionaryBavPage} from "./pages/bavaria/DictionaryBavPage";
import {LanguagesPage} from "./pages/LanguagesPage";
import {GalleryPage} from "./pages/GalleryPage";
import {TransactionsPage} from "./pages/TransactionPage";
import {BavariaEstatePlanDetails} from "./pages/bavaria/BavariaEstatePlanDetails";
import {RecipesBavPage} from "./pages/bavaria/RecipesBavPage";
import {DictionarySchwPage} from "./pages/schwarzerwald/DictionarySchwPage";
import {RecipesSchwarzPage} from "./pages/schwarzerwald/RecipesSchwarzPage";
import {RecipesPage} from "./pages/ResipesPage";
import {ReviewPage} from "./pages/ReviewsPage";
import {getAllProducts} from "./services/ProductService";
import {Product} from "./types/ProductTypes";

const filters = {
    sortField: 'id',
    sortDirection: "",
    limit: 200,
    offset: 0,
}

function App() {
    const [products, setProducts] = useState<Product[]>([]);

    useMemo(async () => {
        const result = await getAllProducts(filters)
        setProducts(result.list)
    }, []);

  return (
      <>
      <ThemeProvider theme={theme}>
      <Router>
          <Header />
          <Routes>
              <Route path="/" element={<MainPage/>} />
              <Route path="/users" element={<UsersPage />} />
              <Route path={"/users/:id/transactions"} element={<TransactionsPage/>} />
              <Route path="/products" element={<ProductsPage />} />
              <Route path="/languages" element={<LanguagesPage />} />
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/recipes" element={<RecipesPage products={products}/>} />
              <Route path="/reviews" element={<ReviewPage />} />

              <Route path="/bavaria/recipes" element={<RecipesBavPage products={products} />} />
              <Route path="/bavaria/industry" element={<IndustryBavPage products={products}/>} />
              <Route path="/bavaria/quests" element={<QuestsBavPage products={products}/>} />
              <Route path="/bavaria/dictionary" element={<DictionaryBavPage products={products}/>} />
              <Route path="/dictionary/bavaria/estate-plan-details/:id" element={<BavariaEstatePlanDetails />} />

              <Route path="/schwarzerwald/recipes" element={<RecipesSchwarzPage products={products} />} />
              {/*<Route path="/schwarzerwald/industry" element={<IndustryBavPage />} />*/}
              {/*<Route path="/schwarzerwald/quests" element={<QuestsBavPage />} />*/}
              <Route path="/schwarzerwald/dictionary" element={<DictionarySchwPage />} />
          </Routes>
      </Router>
      </ThemeProvider>
      </>
  );
}

export default App;
