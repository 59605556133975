import React, {useEffect} from 'react';
import {
    Button, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {SortArrow} from "../../common/SortArrow";
import {addSpaces, getHeaders, replaceCamelCase} from "../../../utils/utils";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import {Quest, QuestCondition} from "../../../types/QuestTypes";
import {deleteQuest, getAllQuests} from "../../../services/QuestService";
import GenerateQuest from "./modals/GenerateQuest";
import EditQuest from "./modals/EditQuest";
import {Product} from "../../../types/ProductTypes";

interface QuestsTableProps {
    reloadTables: boolean;
    products: Product[];
}

export const QuestsBavTable = ({reloadTables, products}: QuestsTableProps) => {
    const [quests, setQuests] = React.useState<Quest[]>([])
    const [page, setPage] = React.useState(0)
    const [sortField, setSortField] = React.useState<string>("")
    const [limit, setLimit] = React.useState(5)
    const [offset, setOffset] = React.useState(0)
    const [sortDirection, setSortDirection] = React.useState<"DESC" | "">("")
    const [selectedField, setSelectedField] = React.useState<string>("")
    const [total, setTotal] = React.useState<number>(0)
    const [headers, setHeaders] = React.useState<Array<string>>([])
    const [reload, setReload] = React.useState<boolean>(true)

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            const result = await getAllQuests({limit, offset, sortField, sortDirection})
            setQuests(result.list);
            setTotal(result.total.totalCount)
            setHeaders(getHeaders(result.list))
        })()
    }, [limit, offset, sortField, sortDirection, reloadTables, reload])
    /* eslint-enable */

    function sortByField(field:string) {
        setSortField(replaceCamelCase(field))
        setSelectedField(field)
    }

    function directionDESC() {
        setSortDirection("DESC")
    }

    function directionDrop() {
        setSelectedField("")
        setSortField("")
        setSortDirection("")
    }

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
        setOffset(newPage * limit)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setLimit(parseInt(event.target.value, 10));
        setOffset(0)
    }

    function reloadThisTable() {
        reload ? setReload(false) : setReload(true);
    }

    return (
        <>
            <Box mt={"3em"} mb={"3em"} mr={"auto"} ml={"auto"}
                 sx={{
                     width: "50%",
                     display: "flex",
                     justifyContent: "center",
                 }}>
                <Typography mr={"1em"} variant={"h5"} component={"h5"}>All Bavaria Quests</Typography>
                <Typography variant={"h5"} component={"span"}>
                    {total}
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers?.map((field) => (
                                <TableCell key={field} align="center" sx={{minWidth: "175px", padding: 0}}>
                                    <Button sx={{
                                        fontSize: "12px",
                                        height: "100%",
                                        width: "40%",
                                        padding: "15px",
                                        margin: 0
                                    }} color={"inherit"}
                                            onClick={() => sortByField(field)}>
                                        {addSpaces(field)}
                                    </Button>
                                    {selectedField === field
                                        ? <SortArrow field={field} directionDESC={directionDESC}
                                                     directionDrop={directionDrop}/>
                                        : ""
                                    }
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    EDIT DATA
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography sx={{fontSize: "12px", fontWeight: 500}} color={"inherit"}>
                                    DELETE DATA
                                </Typography>
                            </TableCell>
                            {quests.length ?
                                <TableCell align="center">
                                    <GenerateQuest products={products} reload={reloadThisTable} />
                                </TableCell>
                                : <></>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quests?.map((values, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: "none"}}
                            >
                                <TableCell align="center">{values.id}</TableCell>
                                <TableCell key={index} sx={{width: "20%", border: "1px solid #dedede !important", p: 0}}>
                                    <Box sx={{display: "flex", width: "100%", borderBottom: "1px solid #dedede !important"}}>
                                        <Box sx={{textAlign: "center",  m: "5px 0", width: "33%", fontWeight: "500", fontSize: "12px"}}>product id</Box>
                                        <Box sx={{textAlign: "center",  m: "5px 0", width: "33%", fontWeight: "500", fontSize: "12px"}}>count</Box>
                                        <Box sx={{textAlign: "center",  m: "5px 0", width: "33%", fontWeight: "500", fontSize: "12px"}}>statistics type</Box>
                                    </Box>
                                    {JSON.parse(String(values.condition)).map((item: QuestCondition, index: number) => (
                                        <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}} key={index}>
                                            <Box sx={{textAlign: "center", display: "flex", width: "33%", m: "5px 0", justifyContent: "center" }}>{item.productId}</Box>
                                            <Box sx={{textAlign: "center", display: "flex", width: "33%", m: "5px 0", justifyContent: "center" }}>{item.count}</Box>
                                            <Box sx={{textAlign: "center", display: "flex", width: "33%", m: "5px 0", justifyContent: "center" }}>{item.statisticsType}</Box>
                                        </Box>
                                    ))
                                    }
                                </TableCell>
                                <TableCell align="center">{values.reward}</TableCell>
                                <TableCell align="center">{values.task}</TableCell>
                                <TableCell align="center">{values.assistText}</TableCell>
                                <TableCell align="center">{values.assistX}</TableCell>
                                <TableCell align="center">{values.assistY}</TableCell>
                                <TableCell align="center">
                                    <EditQuest products={products} values={values} reload={reloadThisTable}/>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => {deleteQuest(values.id).then(reloadThisTable)}}>
                                        <DeleteIcon sx={{color: "#283593"}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <hr style={{marginTop: 0}}/>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                />
            </TableContainer>
        </>
    )
};