import React from 'react'
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import {MenuItem, TextField} from "@mui/material";
import {RecipesTable} from "../components/recipes/ResipesTable";
import {RecipesGainTable} from "../components/recipes/RecipesGainTable";
import {RecipesSpendTable} from "../components/recipes/RecipesSpendTable";
import {Product} from "../types/ProductTypes";

const options = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
]

interface RecipesProps {
    products: Product[];
}

export const RecipesPage = ({products}: RecipesProps) => {
    const [loading, setLoading] = React.useState(false);
    const [reloadTables, setReloadTables] = React.useState<boolean>(false)
    const [farmType, setFarmType] = React.useState<number>(1);

    const reload = () => {
        setLoading(true);
        setTimeout(() => {
            if(reloadTables) {
                setReloadTables(false)
            } else {
                setReloadTables(true)
            }
            setLoading(false)
        }, 2000);
    }

    const changeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFarmType(Number(event.target.value));
        setReloadTables(!reloadTables)
    };

    return(
        <>
            <Box sx={{
                display: "flex",
                mb: "2em",
                mt: "3em",
                maxHeight: "50px"
            }}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="farmType"
                    value={farmType}
                    defaultValue={farmType}
                    onChange={changeOptions}
                    helperText="select farmType"
                    sx={{flexBasis: '10%', margin: "0px auto 0px 3em"}}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <LoadingButton
                    onClick={reload}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{pr: "30px", pl: "30px", margin: "0px 3em 0px auto"}}
                >
                    Reload
                </LoadingButton>
            </Box>
            <Box mt={"3em"} mb={"3em"}>
                <RecipesTable farmType={farmType} reloadTables={reloadTables}/>
            </Box>
            <Box mt={"3em"} mb={"3em"}>
                <RecipesGainTable products={products} farmType={farmType} reloadTables={reloadTables}/>
            </Box>
            <Box mt={"3em"} mb={"3em"}>
                <RecipesSpendTable products={products} farmType={farmType} reloadTables={reloadTables}/>
            </Box>
        </>
    )
}