import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {IconButton, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {postTranslates} from "../../../services/LanguagesService";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    p: 4,
};

interface GenerateLanguageProps {
    reload: () => void;
    lang: number;
}

export default function GenerateLanguage({reload, lang}: GenerateLanguageProps) {
    const [open, setOpen] = React.useState(false)
    const [keyLang, setKeyLang] = React.useState<string>("")
    const [valueLang, setValueLang] = React.useState<string>("")

    const handleOpen= () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <AddBoxIcon sx={{color: "#283593"}} />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > :not(style)': {m: 1},
                        }}
                    >
                        <TextField
                            value={lang}
                            helperText={'lang id'}
                            label={'id'}
                            InputProps={{readOnly: true,}}
                        />
                        <TextField
                            value={keyLang}
                            onChange={(e) =>
                                setKeyLang(e.target.value)
                            }
                            helperText={'edit key'}
                            label={'key'}
                        />
                        <TextField
                            value={valueLang}
                            type={"text"}
                            onChange={(e) =>
                                setValueLang(e.target.value)
                            }
                            helperText={'edit value'}
                            label={'value'}
                        />
                        <Button onClick={() => {
                            postTranslates({
                                idLang: lang,
                                keyLang,
                                valueLang,
                            }).then(() => {handleClose(); reload()});
                        }} size={'large'} variant="contained" endIcon={<SendIcon/>}>
                            Generate
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}